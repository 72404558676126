import { RiMapPinFill } from 'react-icons/ri';
// AdvancedMarkerElement implementation
// import { PiBuildings } from 'react-icons/pi';
import { ReactComponent as btr } from '../../libs/theme/btr.svg';
import { ReactComponent as cth } from '../../libs/theme/cth.svg';
import { ReactComponent as hos } from '../../libs/theme/hos.svg';
import { ReactComponent as ind } from '../../libs/theme/ind.svg';
import { ReactComponent as ls } from '../../libs/theme/ls.svg';
import { ReactComponent as ld } from '../../libs/theme/ld.svg';
import { ReactComponent as mf } from '../../libs/theme/mf.svg';
import { ReactComponent as sfr } from '../../libs/theme/sfr.svg';
import { ReactComponent as sh } from '../../libs/theme/sh.svg';
import './pin.css';
import InfoWindow, { infoContent } from '../InfoWindow';
import { format, vars } from '../../utils/loans';
import { NewToken } from '../Token';
import { loaderMsg, loaderErrMsg } from '../Loader';

const infoid = 'loaninfo';

const showInfo = (evnt, map, maps, id, lat, lng, toggleSlider) => {
  // const parent = evnt.currentTarget;
  const parent = evnt.target.parentElement;
  const infoelem = parent.querySelector(`.${infoid}`);
  if(!infoelem){
    const info = document.createElement('span');
    info.className = infoid;
    info.style = 'position: absolute';
    parent.append(info);

    const infowindow = InfoWindow(map, maps, {lat, lng}, level, toggleSlider);

    NewToken(level).then(newtoken => {
      fetch(`${process.env.REACT_APP_API}/loandetails/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': newtoken
        }
      })
        .then(res => res.json())
        .then(res => {
          if(res.status === 'OK'){
            vars(level);
            const data = format(res.data[0]);
            const details = [
              {
                title: 'General Information',
                fields: [
                  'account_name', 'code_name', 'loan_name', 'portal_loan_name',
                  'type_of_loans', 'loan_performing_status', 'service_status',
                  'address', 'city', 'state',
                  'zip', 'property_type', 'main_purpose',
                  'loan_type', 'stage', 'occupancy',
                  'purchase_price', 'after_repair_value', 'percent_completed',
                  'number_of_units', 'number_of_sub_dash_loans'
                ]
              },
              {
                title: 'Loan Information',
                fields: [
                  'total_loan_amount', 'lien_type', 'total_funded_amount', 'outstanding_amount',
                  'unfunded_commitment', 'total_commitment', 'total_pred_exposure',
                  'loan_sf_status', 'loan_term', 'term_plus_extensions',
                  'recovery_at_stress', 'funding_date', 'original_maturity_date',
                  'maturity_date', 'after_repair_ltv', 'loan_to_cost',
                  'original_debt_yield', 'current_debt_yield', 'target_debt_yield',
                  'pred_expected_irr', 'loan_benchmark',
                  'loan_credit_spread', 'calculated_loan_interest_rate', 'origination_fee',
                  'exit_fee'
                ]
              },
              {
                title: 'Leverage Structure',
                fields: [
                  'total_leverage_amount', 'pred_leverage_share', 'loan_total_leverage_funded',
                  'pred_leverage_funded', 'leverage_percent_calculated', 'leverage_type',
                  'leverage_base_type', 'leverage_credit_spread', 'calculated_leverage_interest_rate'
                ]
              },
              {
                title: 'Borrower',
                fields: [
                  'borrower_name', 'borrower_tier', 'completed_projects',
                  'pnw', 'liquidity', 'pnw_slash_loan_amount',
                  'liquidity_slash_loan_amount'
                ]
              },
              {
                title: 'Internal Rating (1 to 5)',
                fields: [
                  'original_zip_rating', 'original_loan_rating', 'current_zip_rating',
                  'current_loan_rating'
                ]
              },
              {
                title: 'Comments',
                fields: [
                  'comments','reference_id', 'pred_dash_id',
                  'date_of_calculation'
                ]
              }
            ];

            // Commented out to have only 1 access level
            // if(level !== 'admin') details.splice(2, 1);
            details.splice(2, 1);

            if(
              level !== 'admin' &&
              level !== 'lender'
            ){
              details.splice(2, 1);
              details.splice(2, 1);
            }

            infoContent(infowindow, details, data, () => closeInfo(infowindow, info));
          } else {
            loaderMsg(res.message);
            closeInfo(infowindow, info);
          }
        })
        .catch(err => {
          loaderErrMsg();
          closeInfo(infowindow, info);
          console.log(err);
        });
    });
  }
  evnt.stopPropagation();
};

const closeInfo = (infowindow, info) => {
  infowindow.close();
  info.remove();
};

const minpin = Number(process.env.REACT_APP_MINPIN);
const maxpin = Number(process.env.REACT_APP_MAXPIN);
const range = maxpin - minpin;

// Default Pinsize
let pinsize = minpin + 1;

let maxminsub = [];
let level = '';

const setPinVars = (_max, _min, _level) => {
  const sub = (_max - _min) / (range - 1);
  maxminsub = [_max, _min, sub];
  level = _level;
};

const icons = {
  'Build to Rent': btr,
  'Condo / TH': cth,
  'Hospitality': hos,
  'Industrial': ind,
  'Life Science': ls,
  'Lot Development': ld,
  'Multifamily': mf,
  'SFR': sfr,
  'Student Housing': sh
};

function Pin({status, outstanding, images, purpose}){
  status = status.toLowerCase();
  status = status.replace(/ /g, '');

  const main = '#14008E';
  let color = (status === 'performing')? main : '#E91E63';
  // color = (status === 'paid')? '#5A5B6F' : color;
  color = (status.indexOf('paid') >= 0)? '#fff' : color;
  color = (color === main && !images)? '#5A4CAF' : color;

  // Outstanding amount converted to negative if the value is positive and positive if the value is negative
  // because positive value should be the lowest and negative value should be the highest
  let oa = outstanding * -1;

  // Max value set as the min value
  let max = maxminsub[1];
  max = (max < 0)? max * -1 : max;

  // Min value set as the max value
  let min = maxminsub[0];
  min = (min < 0)? min : min * -1;

  if(oa === max){
    // Set max pinsize
    pinsize = maxpin;
  } else if(oa === min){
    // Set min pinsize
    pinsize = minpin;
  } else {
    // Set subtrahend
    let sub = maxminsub[2];
    // Range is how much the sub will be divided
    for(let n = 1; n < range; n++){
      // Diff is the value to match which
      // part of the division the oa belong
      let diff = max - (n * sub);
      if(oa > diff){
        // Set the pinsize based on division
        pinsize = maxpin - n;
        break;
      }
    }
  }

  // Legacy Marker implementation
  // return <RiMapPinFill size={pinsize} color={color} />;

  const icon = icons[purpose];
  const Icon = (icon)? icon : RiMapPinFill;
  return <Icon size={pinsize} height={pinsize} width={pinsize} color={color} />;

  // AdvancedMarkerElement implementation
  /* return (
    <span className="marker">
      <font style={{background: color}}></font>
      <RiMapPinFill size={pinsize} color={color} />
      <PiBuildings size="16" color="white" />
    </span>
  ); */
}

export default Pin;
export { setPinVars, showInfo };
