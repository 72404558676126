import { useEffect } from 'react';
/* Commented out for the theme */
// import { RiFilterOffLine } from 'react-icons/ri';
import { MdOutlineFilterAlt as RiFilterOffLine } from 'react-icons/md';
import './index.css';

import { encodeFilters } from '../../utils/filters';

let addr = {};
let feat = {};
let feat2 = {};
let filtsElem = [];
let filters = [];
let icon = null;
let color = '#fc6400';

const clear = () => {
  addr.value = '';
  addr.form.requestSubmit();

  const change = new Event('change', { bubbles: true });

  feat.selectedIndex = 0;
  feat.dispatchEvent(change);

  feat2.selectedIndex = 0;
  feat2.dispatchEvent(change);

  let filter = {};
  for(filter of filters){
    filter.selectedIndex = 0;
  }
  filter.dispatchEvent(change);
  icon.style.color = '';
};

const clearFilter = () => {
  const redebt = filtsElem.querySelector('select[name="redebt"]');
  const enfil = encodeFilters(filters);
  if(addr.value || feat.value !== '-' ||
    feat2.value !== '-' || enfil ||
    redebt.selectedOptions[0].text !==
    redebt.options[0].innerText
  )
  icon.style.color = color;
  else icon.style.color = '';
};

function Clear(){
  useEffect(() => {
    filtsElem = document.querySelector('.filters');

    addr = filtsElem.querySelector('input');
    addr.addEventListener('input', clearFilter);

    feat = filtsElem.querySelector('[name="features"]');
    feat.addEventListener('change', clearFilter);

    feat2 = filtsElem.querySelector('[name="features2"]');
    feat2.addEventListener('change', clearFilter);

    const filts = filtsElem.querySelectorAll('.filter select');
    for(const filter of filts){
      filter.addEventListener('change', clearFilter);
      // Exclude redebt/fund filter to have specific condition
      // to support the logic in account component which is
      // changing the options based on distinct funds available
      // for the selected investor which also includes changing
      // the default option (not always "-" or "Phoenix PRED")
      if(filter.name !== 'redebt'){
        filters.push(filter);
      }
    }

    icon = filtsElem.querySelector('.clear');
  }, []);

  const color = '#606060';

  return (
    <>
      <RiFilterOffLine size="18" color={color} className="clear" title="Clear filters" onClick={clear} />
      <span className="cleartext" onClick={clear}>Clear Filter</span>
    </>
  );
}

export default Clear;
