import { useState } from 'react';
import Context, { useContext } from '../../context';
import Excel, { ExcelIcon} from '../Excel';
import './index.css';

import {Button } from 'reactstrap';

import {
  loaderShowWithMsg,
  loaderShow, loaderHide,
  loaderMsg, loaderErrMsg
} from '../Loader';

import { Container } from 'reactstrap';
import { NewToken } from '../Token';
import Dropmultisearch from '../Dropmultisearch';
import Filter from '../Filter';

let loaded = 0;
let sortfield = 'Date desc';
let conts = [];
// let accts = [];
// let relate = [];
let dists = [];
let newdists = [];
let setdists = () => void 0;
let currencies = {};
let level = '';
let dropsearch = null;
let completed = 0;
let min = '';
let max = '';
let quarter = null;
let quartOpt = '';
let origNewDists = [];

const initdists = () => {
  if(!loaded){
    loaderShowWithMsg('Processing initial data');

    let start = 0;
    const load = 20;
    const limit = (level === 'admin')?
    5 : Math.ceil(conts.length / load);

    const loadData = () => {
      // Load data in the table
      newdists = dists.slice();
      const sortDists = localStorage.sortDists;
      if(sortDists) sortfield = sortDists;
      sortby(sortfield.split(' ')[0], 1);
      // /Load data in the table
    };

    const distribs = document.querySelector('.distributions');
    dropsearch = distribs.querySelector('.dropsearch select');
    dropsearch.addEventListener('change', () => filtration(dropsearch.value));

    quarter = distribs.querySelector('.quarter select');
    quarter.addEventListener('change', () => filByQuarter(quarter.value));
    quartOpt = quarter.innerHTML;

    const show = () => distribs.querySelector('.dropsearch .show');

    (function repeat(){
      if(start < limit){
        const urls = [];
        conts.slice(load * start, load * (start + 1)).forEach(a => {
          urls.push(getTrans(a.id).then(res => res.json()));
          // const ids = relate.find(b => b.ContactId === a.id);
          // const acct = accts.find(b => b.id === ids.AccountId);
          // console.log(a.id, ids.AccountId, acct.Name);
        });

        Promise.all(urls)
        .then(links => {
          try {
            links.forEach(a => {
              dists.push(...getDist(a.data[0]));
            });

            if(dropsearch.value === '-' && !show()){
              if(!start)
                loaderMsg('Processing of data will keep going');

              const val = quarter.value;
              loadQuarters();

              if(val === '-')
                loadData();
              else {
                origNewDists = [];
                newdists = dists.slice();
                filByQuarter(val);
                quarter.value = val;
              }
            }

            start++;
            repeat();
          } catch(err){
            console.log('error');
            repeat();
          }
        });
      } else {
        loaderShow();
        loaderMsg('Processing of distributions data is completed');

        completed = 1;
        if(show()){
          document.body.addEventListener('click', function self(evnt){
            if(dropsearch.value === '-'){
              loadData();
              loadQuarters();
            }

            const elem = evnt.currentTarget;
            elem.removeEventListener(evnt.type, self);
          });
        }
      }
    })();
    loaded = 1;
  }
};

const getQuarter = (date) => {
  const quart = Math.floor((date.getMonth() + 3) / 3);
  return `Q${quart} ${date.getFullYear()}`;
}

const getQuarters = (start, end) => {
  const quarters = [];
  if(start && end){
    (function generate(start){
      const date = new Date(start);
      quarters.push(getQuarter(date));
      const newDate = new Date(date.setMonth(date.getMonth() - 3));
      if(new Date(end) <= newDate){
        generate(newDate);
      }
    })(start);
  }
  return quarters;
};

const loadQuarters = () => {
  let opts = quartOpt;
  getQuarters(max, min).forEach(a => {
    opts += `<option value="${a}">`;
    opts += `${a}</option>`;
  });
  quarter.innerHTML = opts;
};

const filByQuarter = (val) => {
  if(val !== '-'){
    if(!origNewDists.length)
      origNewDists = newdists.slice();

    newdists = origNewDists.filter(a => {
      const date = new Date(a.Date);
      return getQuarter(date) === val;
    });
  } else {
    newdists = origNewDists.slice();
    origNewDists = [];
  }
  sortby(sortfield.split(' ')[0], 1);
};

const filtration = async (id) => {
  // This try catch is for getTrans()
  try {
    loaderShow();
    min = ''; max = '';
    origNewDists = [];
    if(id !== '-'){
      if(!completed){
        const response = await getTrans(id);
        const res = await response.json();
        const details = getDist(res.data[0]);
        newdists = details;
      } else if(level === 'admin'){
        newdists = dists.filter(a => {
          let stat = false;
          if(a.id === id){
            setDates(a.Date);
            stat = true;
          }
          return stat;
        });

        if(!newdists.length){
          const response = await getTrans(id);
          const res = await response.json();
          const details = getDist(res.data[0]);
          newdists = details;

          if(details.length)
            dists.push(...details);
        }
      } else {
        newdists = dists.filter(a => {
          let stat = false;
          if(a.id === id){
            setDates(a.Date);
            stat = true;
          }
          return stat;
        });
      }
    } else {
      newdists = dists.map(a => {
        setDates(a.Date);
        return a;
      });
    }
    loadQuarters();
    sortby(sortfield.split(' ')[0], 1);
    loaderHide();
  } catch(err){
    loaderErrMsg();
  }
};

const getTrans = async (id) => {
  const newtoken = await NewToken();
  const response = await fetch(`${process.env.REACT_APP_API}/auth/details/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': newtoken
    }
  });
  return response;
};

const getDist = (data) => {
  const id = data.Contacts[0].id;
  const name = data.Accounts[0].Name;
  const dist = [];
  const subs = {};
  let rerun = 0;
  data.Transactions.forEach(a => {
    const type = a.Investment_Type__c;
    const date = a.Date__c;
    const amount = a.Amount__c;
    const series = a.Series_Name__c;
    if(
      type === 'Dividend Distribution' &&
      amount
    ){
      setDates(date);

      const sub = (subs[series])?
      subs[series] : ['', ''];

      if(!sub[0]) rerun = 1;

      dist.push({
        id: id,
        type: type,
        Name: name,
        Date: date,
        Currency: a.CurrencyIsoCode,
        AmountbyCurrency: amount,
        AmountUsd: a.USD_Amount,
        SubDate: sub[0],
        SubAmount: sub[1],
        series: series
      });
    } else if(
      type === 'Subscription' ||
      type === 'Transfer IN' ||
      type === 'Assignment In'
    ){
      subs[series] = [date, amount];
    }
  });

  if(rerun){
    dist.forEach(a => {
      if(a.SubDate) return;

      const sub = (subs[a.series])?
      subs[a.series] : [];
      if(sub.length){
        a.SubDate = sub[0];
        a.SubAmount = sub[1];
      }
    });
  }

  return dist;
};

const setDates = (date) => {
  // Get min date
  if(!min || new Date(min) >
  new Date(date)) min = date;
  // Get max date
  if(!max || new Date(max) <
  new Date(date)) max = date;
};

const fields = {
  // id: ['ID', 'string'],
  // type: ['Type', 'string'],
  Name: ['Account Name', 'string'],
  Date: ['Date', 'string'],
  Currency: ['Currency', 'string'],
  AmountbyCurrency: ['Amount in actual currency', 'number'],
  AmountUsd: ['Amount in USD', 'number'],
  SubDate: ['Subscription Date', 'string'],
  SubAmount: ['Subscription Amount', 'number']
};

const TempTh = ({id, children}) => {
  const sf = sortfield.split(' ');
  let down = '', up = '';
  if(sf[0] === id){
    if(!sf[1]) up = ' active';
    else down = ' active';
  }

  return (
    <th id={id} onClick={() => sortby(id)}>
      {children.replace(/ /g, '\xa0')}&nbsp;
      <span className={"down"+down}>&#9660;</span>
      <span className={"up"+up}>&#9650;</span>
    </th>
  );
};

const string = (field) => {
  newdists.sort((a, b) => {
    let fa = a[field],
        fb = b[field];

    fa = fa? fa.toLowerCase() : '';
    fb = fb? fb.toLowerCase() : '';

    if (fa < fb) return -1;
    if (fa > fb) return 1;
    return 0;
  });
};

const _string = (field) => {
  _sort(newdists, field);
};

const _sort = (data, field) => {
  data.sort((a, b) => {
    let fa = a[field],
        fb = b[field];

    fa = fa? fa.toLowerCase() : '';
    fb = fb? fb.toLowerCase() : '';

    if (fa < fb) return 1;
    if (fa > fb) return -1;
    return 0;
  });
};

const sortby = (field, load = 0) => {
  let sort = (field === sortfield)? ' desc' : '';

  if(!load){
    sortfield = field+sort;
    localStorage.sortDists = sortfield;
  } else sort = (!sort)? ' desc' : '';

  if(
    fields[field][1] === 'number' ||
    fields[field][1] === 'percent'
  ){
    if(!sort)
      newdists.sort((a, b) => a[field] - b[field]);
    else
      newdists.sort((a, b) => b[field] - a[field]);
  } else {
    if(!sort) string(field);
    else _string(field);
  }

  setdists();
};

const asExcel = () => {
  const usd = {
    numFmt: `${currencies.usd}#,##0.##`
  };

  const ils = {
    numFmt: `${currencies.ils}#,##0.##`
  };
  
  const format = {
    usd, ils
  };

  const newdata = newdists.map(a => {
    const columns = {};
    for(const key in fields){
      let val = a[key];
      val = (val)? val : '';
      if(key.indexOf('Amount') >= 0){
        if(val){
          val = parseFloat(val).toFixed(2);
          if(fields[key][0].indexOf('USD') >= 0)
            val = { v: val, t: 'n', s: usd };
          else {
            const cur = a.Currency.toLowerCase();
            val = { v: val, t: 'n', s: format[cur] };
          }
        }
      }
      columns[fields[key][0]] = val;
    }
    return columns;
  });

  Excel(newdata, 'distributions');
};

function Distributions(){
  const Contexts = useContext(Context);
  conts = JSON.parse(Contexts.conts);
  // accts = JSON.parse(Contexts.accts);
  // relate = JSON.parse(Contexts.relate);
  currencies = Contexts.currencies;
  level = Contexts.level;

  const [distributions, setDists] = useState(newdists);
  setdists = () => setDists(newdists);

  newdists = distributions.slice();

  return (
    <>
      <Container fluid className="distributions">
        <h4>Distributions</h4>
        <span className={`actions level ${level}`}>
          <Button color="primary" size="sm" className="distexport" onClick={asExcel}>
            <ExcelIcon /> Export to Excel
          </Button>
          <Filter data={[]} label="All" classes="quarter" />
          <Dropmultisearch data={conts} value="id" options={['Name', 'Nick_Name__c', 'Email', 'MobilePhone']} name="investors" label="All Investors" />
        </span>
        <div className="table dists">
          <table>
            <thead>
              <tr>
                {/* <TempTh key="id" id="id">#</TempTh> */}
                {(() => {
                  const columns = [];
                  for(const key in fields){
                    columns.push(<TempTh key={key} id={key}>{fields[key][0]}</TempTh>);
                  }
                  return columns;
                })()}
              </tr>
            </thead>
            <tbody>
              {
                distributions.map((a, b) => {
                  return (
                    <tr key={b}>
                      {(() => {
                        const columns = [];
                        for(const key in fields){
                          let val = a[key];
                          val = (val)? val : '';
                          if(key.indexOf('Amount') >= 0){
                            if(val){
                              val = val.toLocaleString('en-US', {maximumFractionDigits: 2});
                              if(fields[key][0].indexOf('USD') >= 0) val = currencies.usd+val;
                              else val = currencies[a.Currency.toLowerCase()]+val;
                            }
                          }
                          columns.push(<td key={key}><div>{val}</div></td>);
                        }
                        return columns;
                      })()}
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </Container>
    </>
  );
}

export default Distributions;
export { initdists };