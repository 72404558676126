import { Component } from 'react';
import { Col } from 'reactstrap';
import CanvasJSReact from '../../libs/canvasjs/canvasjs.react';
import './index.css';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

/* let width = window.innerWidth
  || document.documentElement.clientWidth
  || document.body.clientWidth;
width = width - 15; */

const graphopts = {
  backgroundColor: 'transparent',
  // width: width,
  // height: 400,
  toolTip: {
    fontSize: 12,
    cornerRadius: 6,
    fontColor: '#fff',
    backgroundColor: '#25252C',
    borderColor: '#25252C',
    borderThickness: 5,
    content: '<center>{y}<br>{label}</center>'
    // shared: true,
    // contentFormatter: function(e){
    //   console.log(e);
    //   var content = '';
    //   for(var i = 0; i < e.entries.length; i++){
    //     content += e.entries[i].dataPoint.y+'<br>';
    //     content += '<span style="font-size: 5px">';
    //     content += e.entries[i].dataPoint.label;
    //     content += '</span>'
    //   }
    //   return content;
    // }
  },
  axisX : {
    labelFontColor: '#5A5B6F',
    labelFontSize: 12,
    labelMaxWidth: 40,
    labelTextAlign: 'center',
    margin: 10,
    lineColor: '#B5B6C4',
    tickColor: 'transparent'
    // tickLength: 0
  },
  axisY: {
    // title: 'NAV',
    prefix: '$',
    titleFontColor: 'gray',
    lineColor: 'gray',
    tickColor: 'gray',
    labelFontColor: '#3F3F4D',
    labelFontSize: 12,
    includeZero: true,
    lineThickness: 0,
    // gridThickness: 0,
    tickLength: 0,
    // interval: 50000000
    gridColor: '#B5B6C4'
  },
  axisY2: {
    // title: 'Monthly Dividend %',
    suffix: '%',
    titleFontColor: 'gray',
    lineColor: 'gray',
    tickColor: 'gray',
    labelFontColor: 'gray',
    labelFontSize: 12,
    includeZero: true,
    lineThickness: 0,
    tickLength: 0
  },
  // dataPointWidth: 30,
  data: [{
    type: 'column',
    color: '#B5B6C4',
    yValueFormatString: "$#,##0.00",
    dataPoints: []
  },
  {
    type: 'spline',
    yValueFormatString: "0.#0'%'",
    lineColor: '#228489',
    markerType: 'circle',
    markerSize: 6,
    dataPoints: []
  }]
};

class Graph extends Component {
  constructor() {
    super();
    this.createPareto = this.createPareto.bind(this);
  }
  componentDidUpdate(){
    if(this.props.options.data[1].dataPoints.length)
      this.createPareto();
  }
  createPareto(){
    const dps = [];
    const options = this.props.options;
    for(let i = 0; i < options.data[1].dataPoints.length; i++){
      const lineDataPoint = options.data[1].dataPoints[i].y;
      dps.push({
        label: options.data[1].dataPoints[i].label,
        y: lineDataPoint? lineDataPoint : 0,
        color: '#228489'
        // markerSize: 6
      });
    }

    const chart = this.chart;
    options.data[1].dataPoints = dps;
    chart.addTo('data', options.data[1]);
    chart.data[1].set('axisYType', 'secondary', false);
    chart.axisY[0].set('maximum', chart.axisY[0].maximum);
  }
  render(){
    return (
      <Col md={12} className="graph">
        <CanvasJSChart options={this.props.options} onRef={ref => this.chart = ref} />
        <span className="blocker"></span>
        <span className="blocker1"></span>
      </Col>
    );
  }
}

export default Graph;
export { graphopts };
