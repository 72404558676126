import ReactDOMServer from 'react-dom/server';
/* Commented out for the theme */
// import { BsImages } from 'react-icons/bs';
import { BiImage as BsImages } from 'react-icons/bi';
import { Container, Button } from 'reactstrap';
import Excel, { ExcelIcon } from '../Excel';
// import Summary from '../Summary';
import './index.css';

import ModalImages, { toggleImages } from '../ModalImages';
import Upload, { openSelector } from '../Upload';

import { format, vars } from '../../utils/loans';
import { encodeFilters } from '../../utils/filters';
import { NewToken } from '../Token';

let filters = [];
let loaded = 0;
let sortfield = 'pred_dash_id desc';
let level = '';
let loans = null;

const initloans = () => {
  if(!loaded){
    loans = document.querySelector('.loans');
    filters = document.querySelectorAll('.filters .filter select');
    for(const filter of filters){
      filter.addEventListener('change', () => lldata(asTable));
    }

    const view = document.querySelector('.viewmode select');
    if(view){
      view.addEventListener('change', () => mode(view.value));
      mode(view.value);
    } else lldata(asTable);

    loaded = 1;
  }
};

const mode = (val) => {
  level = val.toLowerCase();
  lldata(asTable);
};

const lldata = (callback) => {
  NewToken(level).then(newtoken => {
    let url = `${process.env.REACT_APP_API}/loandetails?type=all`;

    const enfil = encodeFilters(filters);
    if(enfil) url = `${url}&filters=${enfil}`;

    /* const sorter = localStorage.sort;
    sortfield = (sorter)? sorter : sortfield; */
    url = `${url}&sort=${sortfield}`;

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': newtoken
      }
    })
    .then(res => res.json())
    .then(res => {
      vars(level);
      callback(res);
    })
    .catch(err => console.log(err));
  });
};

const hasImgsIcon = (predid, imgs) => {
  const images = <BsImages size="20" pred={predid} imgs={imgs} />;
  return ReactDOMServer.renderToString(images);
};

const noImgsIcon = (predid) => {
  const add = <BsImages size="20" color="#0d6efd" pred={predid} />;
  return ReactDOMServer.renderToString(add);
};

const asTable = (res) => {
  const loanlist = loans.querySelector('.loanlist');
  const loanexport = loans.querySelector('.loanexport');

  const data = (res.data)? res.data : [];
  // Title
  const name = loans.querySelector('h4');
  const title = name.innerHTML;
  const base = title.split(' (')[0];
  name.innerHTML = `${base} (${data.length} loans)`;

  if(res.status === 'OK'){
    // Body
    let tbody = '<tbody>';
    const newdata = data.map((a) => {
      tbody += '<tr>';
      const formatted = format(a);

      // tbody += `<td><div>${b + 1}</div></td>`;
      for(const key in formatted){
        let value = formatted[key];
        if(key !== 'images'){
          if(value === 0 ||
            value === '0' ||
            value === '$0' ||
            value === '0%'
          ) value = '';
        } else {
          const predid = formatted.pred_dash_id;
          if(value.length) value = hasImgsIcon(predid, value);
          else value = (level === 'admin')? noImgsIcon(predid) : '';
        }

        tbody += `<td><div>${value}</div></td>`;
      }
      tbody += '</tr>';
      return formatted;
    });
    tbody += '</tbody>';

    // Header
    const firstdata = newdata[0];
    let thead = '<thead>';
    thead += '<tr>';
    // thead += `<th id="#">#${sort('id')}</th>`;
    for(const key of Object.keys(firstdata)){
      let keyname = key.toUpperCase();
      keyname = keyname.replace(/_/g, '&nbsp;');
      keyname = keyname.replace(/SLASH/g, '/');
      keyname = keyname.replace(/PLUS/g, '+');
      keyname = keyname.replace(/&nbsp;DASH&nbsp;/g, '&minus;');
      keyname = keyname.replace(/&nbsp;AND&nbsp;/g, '&');
      keyname = keyname.replace('OUTSTANDING&nbsp;AMOUNT', 'CALCULATED&nbsp;OUTSTANDING&nbsp;AMOUNT&nbsp;BY&nbsp;DATE');
      keyname = keyname.replace('UNFUNDED&nbsp;COMMITMENT', 'CALCULATE&nbsp;UNFUNDED&nbsp;COMMITMENT');
      keyname = keyname.replace('TOTAL&nbsp;COMMITMENT', 'CALCULATED&nbsp;COMMITMENT&nbsp;BY&nbsp;DATE');
      keyname = keyname.replace('LEVERAGE&nbsp;BASE&nbsp;TYPE', 'LEVERAGE&nbsp;BENCHMARK');
      if(keyname === 'PNW') keyname = keyname.replace('PNW', 'SPONSOR&nbsp;NET&nbsp;WORTH');
      if(keyname === 'LIQUIDITY') keyname = keyname.replace('LIQUIDITY', 'SPONSOR&nbsp;LIQUIDITY');
      keyname = keyname.replace('PNW&nbsp;/&nbsp;LOAN&nbsp;AMOUNT', 'SPONSOR&nbsp;NET&nbsp;WORTH&nbsp;RATIO');
      keyname = keyname.replace('LIQUIDITY&nbsp;/&nbsp;LOAN&nbsp;AMOUNT', 'SPONSOR&nbsp;LIQUIDITY&nbsp;RATIO');

      /* if(level !== 'admin'){
        keyname = keyname.replace('ORIGINAL&nbsp;ZIP&nbsp;RATING', 'ZIP&nbsp;RATING');
        keyname = keyname.replace('ORIGINAL&nbsp;LOAN&nbsp;RATING', 'LOAN&nbsp;RATING');
      } */

      if(key === 'images') thead += `<th>${keyname}</th>`;
      else thead += `<th id="${key}">${keyname}${sort(key)}</th>`;
    }
    thead += '</tr>';
    thead += '</thead>';

    // Table
    let table = '<table>';
    table += thead;
    table += tbody;
    table += '</table>';

    loanlist.innerHTML = table;

    const ths = loanlist.querySelectorAll('th');
    for(const th of ths){
      if(th.id)
        th.addEventListener('click', () => sortby(th.id));
    };

    loanlist.querySelectorAll
    ('td div').forEach(a => {
      // Add title if text has ...
      if(a.scrollWidth > a.clientWidth)
        a.title = a.innerText;
    });

    const svgs = loanlist.querySelectorAll('td div svg');
    for(const svg of svgs){
      const pred = svg.getAttribute('pred');
      const imgs = svg.getAttribute('imgs');

      let show = 0;
      let selectorCallback = () => void 0;
      let imagesCallback = () => void 0;
      let imagesCallback1 = () => void 0;

      if(level === 'admin'){
        show = 1;

        selectorCallback = (svg, imgs) => {
          setTimeout(() => {
            // Update this if api logic is updated
              const main = [];
              const other = [];
              imgs.forEach((a, b) => {
                if(b <= 2) main.push(a);
                else if(a.split('.')[0].toLocaleLowerCase() === 'map') main.push(a);
                else other.push(a);
              });
              imgs = main.concat(other);
            // /Update this if api logic is updated

            const value = hasImgsIcon(pred, imgs);
            const parent = svg.parentElement;
            parent.innerHTML = value;

            const event = (target) => toggleImages(() => imagesCallback(target), (imgs) => imagesCallback1(target, imgs), pred, imgs, show);
            parent.children[0].addEventListener('click', (evnt) => event(evnt.currentTarget));
          }, 3000);
        };

        imagesCallback = (svg) => {
          const value = noImgsIcon(pred);
          const parent = svg.parentElement;
          parent.innerHTML = value;

          const event = (target) => openSelector(pred, (imgs) => selectorCallback(target, imgs));
          parent.children[0].addEventListener('click', (evnt) => event(evnt.currentTarget));
        };

        imagesCallback1 = (svg, imgs) => {
          const value = hasImgsIcon(pred, imgs);
          const parent = svg.parentElement;
          parent.innerHTML = value;

          const event = (target) => toggleImages(() => imagesCallback(target), (imgs) => imagesCallback1(target, imgs), pred, imgs, show);
          parent.children[0].addEventListener('click', (evnt) => event(evnt.currentTarget));
          parent.children[0].dispatchEvent(new Event('click'));
        };
      }

      let event = (target) => toggleImages(() => imagesCallback(target), (imgs) => imagesCallback1(target, imgs), pred, imgs.split(','), show);
      if(!imgs) event = (target) => openSelector(pred, (imgs) => selectorCallback(target, imgs));
      svg.addEventListener('click', (evnt) => event(evnt.currentTarget));
    };

    loanexport.removeAttribute('disabled');
  } else {
    loanlist.innerHTML = `<center>${res.message}</center>`;
    loanexport.disabled = 'true';
  }
};

const asExcel = (res) => {
  const data = res.data;
  const newdata = data.map((a) => {
    delete a.images;
    const formatted = format(a);
    const newformatted = {};
    for(const key in formatted){
      let keyname = key.toUpperCase();
      keyname = keyname.replace(/_/g, ' ');
      keyname = keyname.replace(/SLASH/g, '/');
      keyname = keyname.replace(/PLUS/g, '+');
      keyname = keyname.replace(/ DASH /g, ' - ');
      keyname = keyname.replace(/ AND /g, '&');
      keyname = keyname.replace('OUTSTANDING AMOUNT', 'CALCULATED OUTSTANDING AMOUNT BY DATE');
      keyname = keyname.replace('UNFUNDED COMMITMENT', 'CALCULATE UNFUNDED COMMITMENT');
      keyname = keyname.replace('TOTAL COMMITMENT', 'CALCULATED COMMITMENT BY DATE');
      keyname = keyname.replace('LEVERAGE BASE TYPE', 'LEVERAGE BENCHMARK');
      if(keyname === 'PNW') keyname = keyname.replace('PNW', 'SPONSOR NET WORTH');
      if(keyname === 'LIQUIDITY') keyname = keyname.replace('LIQUIDITY', 'SPONSOR LIQUIDITY');
      keyname = keyname.replace('PNW / LOAN AMOUNT', 'SPONSOR NET WORTH RATIO');
      keyname = keyname.replace('LIQUIDITY / LOAN AMOUNT', 'SPONSOR LIQUIDITY RATIO');

      /* if(level !== 'admin'){
        keyname = keyname.replace('ORIGINAL ZIP RATING', 'ZIP RATING');
        keyname = keyname.replace('ORIGINAL LOAN RATING', 'LOAN RATING');
      } */

      let val = formatted[key];

      if(typeof val === 'string'){
        const cur = '$';
        const per = '%';
        if(val.indexOf(cur) >= 0){
          val = val.replace(cur, '');
          val = val.replace(/,/g, '');
          if(!parseFloat(val)) val = '';

          const amount = {
            numFmt: `${cur}#,##0.###`
          };

          val = { v: val, t: 'n', s: amount };
        } else if(val.indexOf(per) >= 0){
          val = val.replace(per, '');
          if(!parseFloat(val)) val = '';

          const percent = (val) => {
            // val = val.replace(/[0-9]/g, '#');
            val = val.replace(/\d/g, '0');
            return {
              numFmt: `${val}"%"`
            }
          };

          val = { v: val, t: 'n', s: percent(val) };
        }
      } else {
        if(!val) val = '';
      }

      newformatted[keyname] = val;
    }
    return newformatted;
  });
  Excel(newdata, 'loans');
};

const sortby = (field) => {
  field = (field === '#')? 'id' : field;
  sortfield = (field === sortfield)? field+' desc' : field;
  // localStorage.sort = sortfield;

  lldata(asTable);
};

const sort = (field) => {
  const arr = sortfield.split(' ');

  const triup = 'up';
  const tridown = 'down';

  let tri = '&nbsp;';
  tri += `<span class="${tridown}">&#9660;</span>`;
  tri += `<span class="${triup}">&#9650;</span>`;

  if(arr[0] === field){
    if(!arr[1]) tri = tri.replace(triup, triup+' active');
    else tri = tri.replace(tridown, tridown+' active');
  }

  return tri;
};

function Loans(){
  return (
    <Container fluid className="loans">
      {/* <Summary /> */}
      {/* Added h4 for the theme */}
      <h4>Loan List</h4>
      <Button color="primary" size="sm" className="loanexport" onClick={() => lldata(asExcel)}>
        <ExcelIcon /> Export to Excel
      </Button>
      <div className="table loanlist"></div>
      <ModalImages />
      <Upload />
    </Container>
  );
}

export default Loans;
export { initloans };