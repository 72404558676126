let level = '';
const vars = (_level) => {
  level = _level;
};

const format = (data) => {
  const occupancy = data.occupancy;
  if(occupancy && occupancy.indexOf('%') < 0)
    data.occupancy = occupancy+'%';

  data.percent_completed = data.percent_completed+'%';
  // data.number_of_units = data.number_of_units.toLocaleString('en-US');
  data.after_repair_value = '$'+data.after_repair_value.toLocaleString('en-US');
  // data.purchase_price = '$'+data.purchase_price.toLocaleString('en-US');
  data.total_loan_amount = '$'+data.total_loan_amount.toLocaleString('en-US');
  data.after_repair_ltv = data.after_repair_ltv+'%';
  data.loan_to_cost = data.loan_to_cost+'%';

  if(data.target_debt_yield)
    data.target_debt_yield = data.target_debt_yield+'%';

  if(data.loan_credit_spread)
    data.loan_credit_spread = (Math.round(data.loan_credit_spread * 1e2) / 1e2)+'%';

  if(
    level === 'admin' ||
    level === 'lender'
  ){
    data.total_funded_amount = '$'+data.total_funded_amount.toLocaleString('en-US');
    data.outstanding_amount = '$'+data.outstanding_amount.toLocaleString('en-US');
    data.unfunded_commitment = '$'+data.unfunded_commitment.toLocaleString('en-US');
    data.total_commitment = '$'+data.total_commitment.toLocaleString('en-US');
    data.recovery_at_stress = data.recovery_at_stress+'%';
    data.original_debt_yield = data.original_debt_yield+'%';
    data.current_debt_yield = data.current_debt_yield+'%';
    data.loan_base_floor = data.loan_base_floor+'%';
    data.calculated_loan_interest_rate = data.calculated_loan_interest_rate+'%';
    data.pred_expected_irr = data.pred_expected_irr+'%';
    data.total_leverage_amount = '$'+data.total_leverage_amount.toLocaleString('en-US');
    data.pred_leverage_share = '$'+data.pred_leverage_share.toLocaleString('en-US');
    data.loan_total_leverage_funded = '$'+data.loan_total_leverage_funded.toLocaleString('en-US');
    data.pred_leverage_funded = '$'+data.pred_leverage_funded.toLocaleString('en-US');
    data.leverage_percent_calculated = data.leverage_percent_calculated+'%';
    data.leverage_credit_spread = data.leverage_credit_spread+'%';
    data.calculated_leverage_interest_rate = data.calculated_leverage_interest_rate+'%';
    data.origination_fee = data.origination_fee+'%';
    data.exit_fee = data.exit_fee+'%';
    data.pnw = '$'+data.pnw.toLocaleString('en-US');
    data.liquidity = '$'+data.liquidity.toLocaleString('en-US');
    data.pnw_slash_loan_amount = data.pnw_slash_loan_amount+'%';
    data.liquidity_slash_loan_amount = data.liquidity_slash_loan_amount+'%';
    data.current_zip_rating = rate(data.current_zip_rating).num;
    data.current_loan_rating = rate(data.current_loan_rating).num;
  } else {
    // data.original_zip_rating = rate(data.original_zip_rating).num;
    // data.original_loan_rating = rate(data.original_loan_rating).num;
  }

  return data;
};

const rate = (rank) => {
  let txt = '';
  let num = '';
  let result = { txt, num };

  if((rank && rank !== '') || rank === 0){
    if(isNaN(rank)) rank = rank.toLowerCase();

    txt = 'aaa';
    num = 5;
    if((rank <= 280 && rank > 246) || rank === 'aa+'){
      txt = 'aa+';
      num = 5;
    } else if((rank <= 246 && rank > 215) || rank === 'aa'){
      txt = 'aa';
      num = 4.5;
    } else if((rank <= 215 && rank > 180) || rank === 'aa-'){
      txt = 'aa-';
      num = 4.5;
    } else if((rank <= 180 && rank > 153) || rank === 'a+'){
      txt = 'a+';
      num = 4;
    } else if((rank <= 153 && rank > 126) || rank === 'a'){
      txt = 'a';
      num = 4;
    } else if((rank <= 126 && rank > 100) || rank === 'a-'){
      txt = 'a-';
      num = 3.5;
    } else if((rank <= 100 && rank > 86) || rank === 'bbb+'){
      txt = 'bbb+';
      num = 3.5;
    } else if((rank <= 86 && rank > 73) || rank === 'bbb'){
      txt = 'bbb';
      num = 3;
    } else if((rank <= 73 && rank > 60) || rank === 'bbb-'){
      txt = 'bbb-';
      num = 3;
    } else if((rank <= 60 && rank > 46) || rank === 'bb+'){
      txt = 'bb+';
      num = 2.5;
    } else if((rank <= 46 && rank > 33) || rank === 'bb'){
      txt = 'bb';
      num = 2.5;
    } else if((rank <= 33 && rank > 20) || rank === 'bb-'){
      txt = 'bb-';
      num = 2;
    } else if((rank <= 20 && rank > 6) || rank === 'b+'){
      txt = 'b+';
      num = 2;
    } else if((rank <= 6 && rank > -7) || rank === 'b'){
      txt = 'b';
      num = 1.5;
    } else if((rank <= -7 && rank > -20) || rank === 'b-'){
      txt = 'b-';
      num = 1.5;
    } else if((rank <= -20 && rank > -1000) || rank === 'ccc'){
      txt = 'ccc';
      num = 1;
    } else if((rank <= -1000) || rank === 'cc'){
      txt = 'cc';
      num = 1;
    }
    result = { txt: txt.toUpperCase(), num };
  }

  return result;
};

export { format, vars, rate };