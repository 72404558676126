import Context, { useContext } from '../../context';
import { GiBackwardTime } from 'react-icons/gi';
import './index.css';

import { NewToken } from '../Token';
import { toggleConfirm } from '../Confirm';

import {
  loaderShowWithMsg, loaderMsg,
  loaderErrMsg
} from '../Loader';

const rollBack = () => {
  loaderShowWithMsg('Rolling back loans data will only take a few seconds');

  NewToken().then(newtoken => {
    fetch(`${process.env.REACT_APP_API}/auth/rollback`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': newtoken
      }
    })
      .then(res => res.json())
      .then(res => {
        loaderMsg(res.message);
        setTimeout(() => {
          toggleConfirm();

          if(res.status === 'OK'){
            // Trigger dispatch on the first map filter to update the displays
            document
            .querySelector('.filters .filter select')
            .dispatchEvent(new Event('change'));
          }
        }, 3000);
      })
      .catch(err => {
        loaderErrMsg();
        console.log(err);
      });
  });
}

function RollBackLoans(){
  const Contexts = useContext(Context);
  const signin = Contexts.signin;
  const viewmode = Contexts.viewmode;

  const toggleRollBack = () => {
    toggleConfirm({
      action: 'rollback loans data?<br><br>WARNING! this action will DELETE the CURRENT DATA and will NOT be reversible, would you still want to proceed',
      label: 'Rollback',
      confirm: rollBack
    });
  };

  return !signin || viewmode !== 'admin'? '' : (
    <font onClick={toggleRollBack}>
      <GiBackwardTime size="24" className="rollBackLoans" title="Rollback loans" />
    </font>
  );
}

export default RollBackLoans;