import { useState, useEffect, useRef } from 'react';
import {
  Dropdown, DropdownToggle, DropdownMenu,
  DropdownItem, Input
} from 'reactstrap';
import './index.css';

import Filter from '../Filter';

let time = () => void 0;
let propsData = [];
let newPropsData = [];
let setdata = () => void 0;
let options = [];
let start = 0;
let load = 100;
let select = null;
let search = null;
let events = () => void 0;

const filter = (evnt) => {
  start = 0;
  const self = evnt.currentTarget;

  clearTimeout(time);
  time = setTimeout(() => {
    const data = propsData.filter(a => {
      const str = self.value.toLowerCase();
      const val = Object.values(a).join().toLowerCase();
      return val.indexOf(str) >= 0;
    });
    setdata(data);

    setTimeout(() => {
      events();
    }, 1);
  }, 100);
};

const sort = (data) => {
  data.sort((a, b) => {
    let fa = a[options[0]],
        fb = b[options[0]];

    fa = fa? fa.toLowerCase() : '';
    fb = fb? fb.toLowerCase() : '';

    if (fa < fb) return -1;
    if (fa > fb) return 1;
    return 0;
  });
};

const labels = {};
const display = (evnt) => {
  const self = evnt.currentTarget;
  const td = self.querySelector('td');
  const selftext = (td)? td.innerText : self.innerText;

  const selopt = select.options[0];
  const seltext = selopt.innerText;

  if(selftext !== seltext){
    if(!labels[select.name]) labels[select.name] = select.innerText;
    selopt.innerText = (self.id === '-')? labels[select.name] : selftext;
    selopt.value = self.id;

    // Trigger change event
    const change = new Event('change', { bubbles: true });
    select.dispatchEvent(change);
  }
  document.body.click();
};

const loadmore = (evnt) => {
  start++;
  const self = evnt.currentTarget;
  const button = self.previousElementSibling;
  newPropsData.slice(load * start, load * (start + 1)).forEach(a => {
    const clone = button.cloneNode(true);
    clone.addEventListener('click', (evnt) => {
      start = 0;
      display(evnt);
    });
    clone.id = a.id;
    self.parentElement.insertBefore(clone, self);

    const tds = clone.querySelectorAll('td div');
    options.forEach((v, i) => {
      const td = tds[i];
      td.innerHTML = a[v];

      // Add title if text has ...
      if(td.scrollWidth > td.clientWidth)
        td.title = td.innerText;
    });
  });

  search.focus();

  if(newPropsData.length <= load * (start + 1))
    self.style.display = 'none';
};

const Temp = ({data}) => {
  return (
    <div className="table">
      <table>
        <tbody>
          <tr>
            {
              options?.map((v, i) => {
                return (
                  <td key={i}>
                    <div>{data[v]}</div>
                  </td>
                );
              })
            }
          </tr>
        </tbody>
      </table>
    </div>
  );
};

function Dropmultisearch(props){
  const [isOpen, setIsOpen] = useState(false);

  options = props.options;
  sort(props.data);
  propsData = props.data;
  const [data, setData] = useState(propsData);
  setdata = (data) => setData(data);

  const ref = useRef(null);

  useEffect(() => {
    const dropsearch = ref.current;
    dropsearch.querySelector('select')
    .addEventListener('click', (evnt) => {
      setIsOpen(true);
      select = evnt.currentTarget;

      setTimeout(() => {
        search = dropsearch.querySelector('[name="search"]');
        search.focus();

        // Add event for Search
        search.addEventListener('keydown', filter);

        events();
      }, 1);

      events = () => {
        // Add event for default option
        dropsearch.querySelector('.dropdown-item')
        .addEventListener('click', display);

        // Options and title
        dropsearch.querySelectorAll
        ('.res .dropdown-item').forEach(a => {
          // Add event for options
          a.addEventListener('click', display);

          a.querySelectorAll
          ('td div').forEach(b => {
            // Add title if text has ...
            if(b.scrollWidth > b.clientWidth)
              b.title = b.innerText;
          });
        });

        // Add event for Load more
        dropsearch.querySelector('.res span')
        .addEventListener('click', loadmore);
      };
    });
  }, []);

  const close = () => {
    setIsOpen(false);
    setdata(propsData);
    start = 0;
  };

  newPropsData = data.slice(0);
  const len = data.length;
  const style = (len > load)? {} : {display: 'none'};

  const total = propsData.length;
  const count = total.toLocaleString('en-US', {maximumFractionDigits: 0});

  return (
    <div className="dropsearch" ref={ref}>
      <Filter data={[]} name={props.name} label={props.label} />
      <Dropdown isOpen={isOpen} toggle={close} className="opts">
        <DropdownToggle size="sm" className="toggler">Toggler</DropdownToggle>
        <DropdownMenu>
          <div className="search">
            <Input name="search" placeholder="Search" autoComplete="off" />
          </div>
          <div className="result">
            <DropdownItem id="-">{props.label} ({count} record{total > 1? 's' : ''})</DropdownItem>
            <div key={new Date().getTime()} className="res">
              {data.slice(start, load)?.map((v, i) => <DropdownItem key={i} id={v[props.value]}><Temp data={v} /></DropdownItem>)}
              <span style={style}>Load more</span>
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

export default Dropmultisearch;
