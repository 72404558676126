import { useState, useEffect } from 'react';
import Context, { useContext } from '../../context';
import Filters from '../../components/Filters';
// import Map from '../../components/Map';
import Map, { initmap } from '../../components/Map/map';
import Label from '../../components/Label';
import Loans, { initloans } from '../../components/Loans';
import Statistics, { initstats } from '../../components/Statistics';
import { inittotals } from '../../components/Totals';
// import { initsummary } from '../../components/Summary';
import Account, { initaccount } from '../../components/Account';
import Documents, { initdocs } from '../../components/Documents';
import Commissions, { initcomms } from '../../components/Commissions';
import Distributions, { initdists } from '../../components/Distributions';
import './index.css';

import {
  TabContent, TabPane, Nav,
  NavItem, NavLink,
} from 'reactstrap';
import classnames from 'classnames';
import { loaderSet, loaderHide } from '../../components/Loader';

let body = {};
let addr = {};
let go = {};
let feat = {};
let feat2 = {};
let filters = {};
let level = '';

const myaccount = () => {
  body.classList.add('nomap');
  body.classList.add('acctpage');
  addr.disabled = 'true';
  go.disabled = 'true';
  feat.disabled = 'true';
  feat2.disabled = 'true';
  filters.style.display = 'none';
  initaccount();
};

const dynamicmap = () => {
  addr.removeAttribute('disabled');
  go.removeAttribute('disabled');
  if(feat) feat.removeAttribute('disabled');
  if(feat2) feat2.removeAttribute('disabled');
  filters.removeAttribute('style');
  initmap();
};

const loanlist = () => {
  body.classList.add('nomap');
  addr.disabled = 'true';
  go.disabled = 'true';
  feat.disabled = 'true';
  feat2.disabled = 'true';
  filters.removeAttribute('style');
  initloans();
  // initsummary();
};

const statistics = () => {
  body.classList.add('nomap');
  body.classList.add('statpage');
  addr.disabled = 'true';
  go.disabled = 'true';
  feat.disabled = 'true';
  feat2.disabled = 'true';
  filters.removeAttribute('style');
  initstats();
  inittotals();
};

const documents = () => {
  body.classList.add('nomap');
  body.classList.add('docspage');
  filters.style.display = 'none';
  initdocs();
};

const commissions = () => {
  body.classList.add('nomap');
  body.classList.add('commspage');
  filters.style.display = 'none';
  initcomms();
};

const distributions = () => {
  body.classList.add('nomap');
  body.classList.add('distspage');
  filters.style.display = 'none';
  initdists();
};

function Home(){
  const Contexts = useContext(Context);
  const { signin, viewmode } = Contexts;
  level = Contexts.level;

  useEffect(() => {
    /* const ws = new WebSocket(`ws://localhost:4001`);

    ws.onopen = () => {
      console.log('Connected to the server');

      const id = new Date().getTime();
      console.log(id);
      ws.send(`This id is connected: ${id}`);
    };

    ws.onmessage = (event) => {
      console.log('Messages: '+event.data);
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    ws.onclose = () => {
      console.log('Disconnected from the server');
    }; */

    loaderSet();
    loaderHide();

    if(signin){
      document.title = document.title.replace('A2One', 'Portal');

      body = document.body;
      addr = document.querySelector('.filters input');
      go = document.querySelector('.address button');
      feat = document.querySelector('[name="features"]');
      feat2 = document.querySelector('[name="features2"]');
      filters = document.querySelector('.filters .only');

      myaccount();

      const view = document.querySelector('.viewmode select');

      // Uncomment below incase of my account page restriction (reseller/investor)
      // Then merge the script for restrict loan list page in else block
      /* const map = document.querySelector('.maptabs #dynamicMap');
      if(view){
        view.addEventListener('change', () => {
          level = view.value.toLowerCase();
          if(level !== 'admin' &&
            body.classList.contains('acctpage')
          ) map.click();
        });
        view.dispatchEvent(new Event('change'));
      } else {
        map.click();
        const account = document.querySelector('.accountpane');
        const details = account.querySelector('.details');
        if(details){
          details.remove();
          account.querySelector('.infos').remove();
          account.querySelector('.investment').remove();
        }
      } */

      if(!view){
        // This condition is added for my account page restriction (lender)
        if(docsPageAccess){
          // This condition is added for loan list page restriction (investor)
          /* if(loansPageAccess){
            // Restrict loan list page
            const loan = document.querySelector('.loanpane');
            const loans = loan.querySelector('.loans');
            if(loans) loans.remove();
          } */

          /* if(mapPageAccess){
            // Remove elements in map page
            document.querySelector('.mappane').innerHTML = '';
          } */

          // This condition is added for page restriction (investor/reseller)
          if(mapPageAccess){
            // Restrict loan list page
            const loan = document.querySelector('.loanpane');
            const loans = loan.querySelector('.loans');
            if(loans) loans.remove();

            // Remove elements in map page
            document.querySelector('.mappane').innerHTML = '';
          }
        } else {
          // This is for my account page restriction (lender)
          document.querySelector('.maptabs #dynamicMap').click();
          const account = document.querySelector('.accountpane');
          const details = account.querySelector('.details');
          if(details){
            details.remove();
            account.querySelector('.infos').remove();
            account.querySelector('.investment').remove();
          }
        }
      }
    }
  }, [signin]); // eslint-disable-line

  const [actab, setActab] = useState('1');
  const toggle = tab => {
    body.classList.remove('nomap');
    body.classList.remove('acctpage');
    body.classList.remove('statpage');
    body.classList.remove('docspage');
    body.classList.remove('commspage');
    body.classList.remove('distspage');
    if(actab !== tab) setActab(tab);
  };

  const commsPageAccess =
  viewmode === 'admin' ||
  viewmode === 'reseller';

  const docsPageAccess =
  viewmode !== 'lender';

  const loansPageAccess =
  viewmode === 'investor';

  const mapPageAccess =
  loansPageAccess ||
  viewmode === 'reseller';

  const distsPageAccess =
  docsPageAccess;

  return (
    <>
      <Filters />
      {(() => {
        if(signin){
          return (
            <>
              <Nav tabs className={`maptabs level ${level}`}>
                {/* Use this incase of my account page restriction (reseller/investor) */}
                {/* <NavItem className={`level ${level}`}> */}
                <NavItem>
                  <NavLink
                  id="myAccounts"
                  className={classnames({ active: actab === '1' })}
                  onClick={() => { toggle('1'); myaccount(); }}>
                    My Accounts
                  </NavLink>
                </NavItem>
                <NavItem className={`maplink level ${level}`}>
                {/* <NavItem> */}
                  <NavLink
                  id="dynamicMap"
                  className={classnames({ active: actab === '2' })}
                  onClick={() => { toggle('2'); dynamicmap();  }}>
                    Dynamic Map
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                  className={classnames({ active: actab === '4' })}
                  onClick={() => { toggle('4'); statistics(); }}>
                    Statistics
                  </NavLink>
                </NavItem>
                <NavItem className={`loanlink level ${level}`}>
                {/* <NavItem> */}
                  <NavLink
                  id="loanList"
                  className={classnames({ active: actab === '3' })}
                  onClick={() => { toggle('3'); loanlist(); }}>
                    Loan List
                  </NavLink>
                </NavItem>
                {(() => {
                  if(docsPageAccess){
                    return (
                      <NavItem>
                        <NavLink
                        id="documents"
                        className={classnames({ active: actab === '5' })}
                        onClick={() => { toggle('5'); documents(); }}>
                          Documents
                        </NavLink>
                      </NavItem>
                    );
                  }
                })()}
                {(() => {
                  if(commsPageAccess){
                    return (
                      <NavItem className={`commlink level ${level}`}>
                        <NavLink
                        id="commissions"
                        className={classnames({ active: actab === '6' })}
                        onClick={() => { toggle('6'); commissions(); }}>
                          Commissions
                        </NavLink>
                      </NavItem>
                    );
                  }
                })()}
                {(() => {
                  if(distsPageAccess){
                    return (
                      <NavItem>
                        <NavLink
                        id="distributions"
                        className={classnames({ active: actab === '7' })}
                        onClick={() => { toggle('7'); distributions(); }}>
                          Distributions
                        </NavLink>
                      </NavItem>
                    );
                  }
                })()}
              </Nav>

              <TabContent activeTab={actab}>
                {/* Use this incase of my account page restriction (reseller/investor) */}
                {/* <TabPane tabId="1" className={`accountpane level ${level}`}> */}
                <TabPane tabId="1" className="accountpane">
                  <Account />
                </TabPane>
                <TabPane tabId="2" className={`mappane level ${level}`}>
                  <Map />
                  <Label />
                </TabPane>
                <TabPane tabId="4" className="statpane">
                  <Statistics />
                </TabPane>
                <TabPane tabId="3" className={`loanpane level ${level}`}>
                {/* <TabPane tabId="3" className="loanpane"> */}
                  <Loans />
                </TabPane>
                {(() => {
                  if(docsPageAccess){
                    return (
                      <TabPane tabId="5" className="docpane">
                        <Documents />
                      </TabPane>
                    );
                  }
                })()}
                {(() => {
                  if(commsPageAccess){
                    return (
                      <TabPane tabId="6" className={`commpane level ${level}`}>
                        <Commissions />
                      </TabPane>
                    );
                  }
                })()}
                {(() => {
                  if(distsPageAccess){
                    return (
                      <TabPane tabId="7" className="distpane">
                        <Distributions />
                      </TabPane>
                    );
                  }
                })()}
              </TabContent>
            </>
          );
        } else {
          return (
            <TabContent activeTab={actab}>
              <TabPane tabId="1" className="mappane">
                <Map />
              </TabPane>
            </TabContent>
          );
        }
      })()}
    </>
  );
}

export default Home;